import TraceKit from 'tracekit'
import { _axios } from '@/utils/axios'
import { utils } from "@/utils"


// 错误日志上报
TraceKit.report.subscribe(function logger (errorReport) {
  if (errorReport.mode === 'stack') {
    let fileName = errorReport.stack[0].url.split(`${utils.getWebUrl()}js/`)[1]
    _axios({
      url: `/log/web`,
      method: 'post',
      data: {
        error: {
          project: 'carpool',
          name: errorReport.name,
          message: errorReport.message,
          fileName,
          line: errorReport.stack[0].line,
          column: errorReport.stack[0].column
        }
      }
    })
  }
})

const reportError = TraceKit.report

export { reportError }