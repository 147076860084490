<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'App',
  data() {
    return {
      transitionName: 'fold-left',
    }
  },
  watch: {
    // watch $route 决定使用哪种过渡
    $route(to, from) {
      //to、from是最基本的路由对象，分别表示从(from)某个页面跳转到(to)另一个页面,to.path（表示要跳转到的路由地址），from.path同理。
      const routerDeep = [
        '/carpool/zhimache',
        '/carpool/kuaijieche',
        '/carpool/baoche',
        '/caroool/shangche',
        '/carpool/baoche/invite-member',
        '/accelerator',
        '/accelerator/info',
        '/carppol/purchase/pay-pc',
        '/carpool/purchase/pay-success',
        '/eshop',
        '/eshop/detail',
        '/eshop/order',
        '/eshop/pay-pc',
        '/eshop/pay-success',
        '/order',
        '/customer-service',
      ]
      //找到to.path和from.path在routerDeep数组中的下标
      const toDepth = routerDeep.indexOf(to.path)
      const fromDepth = routerDeep.indexOf(from.path)
      this.transitionName = toDepth > fromDepth ? 'fold-left' : 'fold-right'
    },
  },
}
</script>

<style scoped lang="less">
#app {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 7.5rem;
  height: 100% !important;
  min-height: 100% !important;
}
</style>
