import axios from 'axios'
import { utils } from '@/utils'

// let token = getToken()

const config = {
  baseURL: utils.getApiUrl(),
  timeout: 60000
}

export const _axios = axios.create(config)

// _axios.defaults.headers.common['Youthgg-Admin'] = true
// if (token) {
//   _axios.defaults.headers['Authorization'] = token
// }

// _axios.interceptors.request.use(
//   cfg => cfg,
//   error => Promise.reject(error),
// )

// Plugin.install = function(Vue) {
//   Vue.axios = _axios;
//   window.axios = _axios;
//   Object.defineProperties(Vue.prototype, {
//     axios: {
//       get() {
//         return _axios;
//       },
//     },
//     $axios: {
//       get() {
//         return _axios;
//       },
//     },
//   });
// };

// Vue.use(Plugin)

// export default Plugin;