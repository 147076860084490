import { _axios } from "@/utils/axios"

export function getUserinfo(params) {
  return _axios({
    url: "/user",
    method: "get",
    params,
  })
}

export function getCarpoolInfo(params) {
  return _axios({
    url: "/user/carpool/info",
    method: "get",
    params,
  })
}

export function getCoupons(params) {
  return _axios({
    url: "/user/coupons",
    method: "get",
    params,
  })
}

export function updateUser(data) {
  return _axios({
    url: `/user`,
    method: "put",
    data,
  })
}
