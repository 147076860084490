import { _axios } from '@/utils/axios'

export function getJsapiSign(params) {
  if (params) {
    params.random = Math.random()
  }
  return _axios({
    url: '/common/jsapisign',
    method: 'get',
    params
  })
}

export function events(data) {
  return _axios({
    url: '/events',
    method: 'put',
    data
  })
}
