import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { reportError } from "@/utils/log"
import { getJsapiSign } from "@/api/common"
import wx from "weixin-js-sdk"
import { getUserinfo } from "@/api/user"
import { utils } from "@/utils"

Vue.config.productionTip = false

const isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //iOS

let ua = navigator.userAgent.toLowerCase()

const isWeixin = (/micromessenger/i.test(ua) || /windows phone/i.test(ua))

const isPC = !navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

Vue.prototype.$reportError = reportError
Vue.prototype.$isPC = isPC
Vue.prototype.$isWeixin = isWeixin

/*
* 获取微信签名，注入权限验证配置
* */
export async function requestWxStr () {
  let url = location.href.split('#')[0]
  let res = await getJsapiSign({
    url
  })
  if (res.data.code == 0) {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: "wxadf4a59cd613d104", // 必填，公众号的唯一标识
      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.noncestr, // 必填，生成签名的随机串
      signature: res.data.signature, // 必填，签名
      jsApiList: ["chooseImage", "uploadImage"], // 必填，需要使用的JS接口列表
    })
  }
}

if (isIOS && isWeixin) {
  requestWxStr()
}

let code = ''

const getUuid = async () => {
  let params = {}
  if (code) {
    params['code'] = code
  }
  let res = await getUserinfo(params)
  if (res.data.code == 0) {
    localStorage.setItem('uuid', res.data.userinfo.uuid)
  } else {
    window.location.href = 'https://carpool.yyouren.com'
  }
}

let cf = utils.getUrlKey('cf') || document.referrer
if (cf) {
  localStorage.setItem('cf', cf)
}

let ic = utils.getUrlKey('ic')
if (ic) {
  localStorage.setItem('ic', ic)
}

let uuid = localStorage.getItem('uuid')
if (!uuid && uuid != 'undefined') {
  if (isWeixin) {
    if (!utils.getUrlKey('code')) { // 获取code
      let appid = 'wx1d62934604321a9c'
      let redirectUrl = window.location.href
      redirectUrl = encodeURI(redirectUrl)
      let authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base#wechat_redirect` 
      window.location.href = authUrl
    } else {
      code = utils.getUrlKey('code')
      getUuid()
    }
  } else {
    getUuid()
  }
}

router.beforeEach((to, from, next) => { // beforeEach是router的钩子函数，在进入路由前执行
  /* vue-router用的history模式，在微信签名验证的时候iOS验证URL第一次进来的URL */
  if (from.path === '/') {
    if (to.path == '/baoche/shangche' || to.path == '/invite-member') {
      next({ path: '/home' })
    }
  }
  next()
})


const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0]
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  if (htmlWidth >= 750) {
    htmlWidth = 750
  }
  if (htmlWidth <= 320) {
    htmlWidth = 320
  }
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`
}
window.onresize = setHtmlFontSize
setHtmlFontSize()

window.addEventListener('error', (errorEvent) => {
  reportError(errorEvent)
}, true)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')