import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/carpool/kuaijieche',
    component: () => import('../views/layout/Layout.vue'),
    children: [
      {
        path: 'carpool',
        component: () => import('../views/carpool/Index.vue'),
        children: [
          {
            path: 'zhimache',
            component: () => import('../views/carpool/Zhimache.vue'),
          },
          {
            path: 'kuaijieche',
            component: () => import('../views/carpool/Kuaijieche.vue'),
          },
          {
            path: 'baoche',
            component: () => import('../views/carpool/baoche/Index.vue'),
          },
        ],
      },
      {
        path: 'carpool/baoche/invite-member',
        name: 'InviteMember',
        component: () => import('../views/carpool/baoche/InviteMember.vue'),
      },
      {
        path: 'accelerator',
        name: 'accelerator',
        component: () => import('../views/accelerator/Index.vue'),
      },
      {
        path: 'accelerator/order/pay-success',
        name: 'accelerator/pac/success',
        component: () => import('../views/accelerator/order/PaySuccess.vue'),
      },
      {
        path: 'accelerator/order/pay-pc',
        name: 'accelerator/pay/pc',
        component: () => import('../views/accelerator/order/PayPC.vue'),
      },
      {
        path: 'accelerator/info',
        name: 'acceleratorInfo',
        component: () => import('../views/accelerator/Info.vue'),
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('../views/order/Index.vue'),
      },
      {
        path: 'carpool/purchase/pay-success',
        name: 'PaySuccess',
        component: () => import('../views/carpool/purchase/PaySuccess.vue'),
      },
      {
        path: 'carpool/renewal/pay-success',
        name: 'PaySuccess',
        component: () => import('../views/carpool/renewal/PaySuccess.vue'),
      },
      {
        path: 'carpool/purchase/pay-pc',
        name: 'PayPC',
        component: () => import('../views/carpool/purchase/PayPC.vue'),
      },
      {
        path: 'customer-service',
        name: 'CS',
        component: () => import('../views/customer-service/Index.vue'),
      },
      {
        path: 'eshop',
        component: () => import('../views/eshop/Index.vue'),
      },
      {
        path: 'eshop/detail',
        component: () => import('../views/eshop/Detail.vue'),
      },
      {
        path: 'eshop/pay-success',
        component: () => import('../views/eshop/PaySuccess.vue'),
      },
      {
        path: 'eshop/pay-pc',
        name: 'PayPC',
        component: () => import('../views/eshop/PayPC.vue'),
      },
    ],
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/carpool/kuaijieche'
  },
  {
    path: '/eshop/order',
    component: () => import('../views/eshop/Order.vue'),
  },
  {
    path: '/carpool/shangche',
    name: 'Shangche',
    component: () => import('../views/carpool/purchase/Index.vue'),
  },
  {
    path: '/carpool/upgrade',
    name: 'Shangche',
    component: () => import('../views/carpool/purchase/Upgrade.vue'),
  },
  {
    path: '/carpool/activation',
    name: 'activation',
    component: () => import('../views/carpool/activation/Index.vue'),
  },
  {
    path: '/carpool/renewal',
    name: 'renewal',
    component: () => import('../views/carpool/renewal/Index.vue'),
  },
  {
    path: '/carpool/renewal/purchase',
    name: 'purchase',
    component: () => import('../views/carpool/renewal/Purchase.vue'),
  },
  {
    path: '/accelerator/order',
    name: 'accelerator/order',
    component: () => import('../views/accelerator/order/Index.vue'),
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('../views/promotion/Index.vue'),
  },
  {
    path: '/invitation-bounty',
    name: 'Invitation',
    component: () => import('../views/invitation-bounty/Index.vue'),
  },
  {
    path: '/invitation-bounty/rules',
    name: 'InvitationRules',
    component: () => import('../views/invitation-bounty/Rule.vue'),
  },
  {
    path: '/invitation-bounty/withdraw',
    name: 'InvitationWithdraw',
    component: () => import('../views/invitation-bounty/Withdraw.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router